@font-face {
    font-family: 'CaviarBold';
    src: url('/src/fonts/caviar_dreams/CaviarDreams_Bold.ttf');
  }

@font-face {
    font-family: 'CaviarItalic';
    src: url('/src/fonts/caviar_dreams/CaviarDreams_Italic.ttf');
  }

@font-face {
    font-family: 'Caviar';
    src: url('/src/fonts/caviar_dreams/CaviarDreams.ttf');
  }

@font-face {
    font-family: 'Caroline';
    src: url('/src/fonts/caroline/Caroline.ttf');
  }

.services-container { 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }

.stack-row-container-services {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
}

.stack-row-container-read-more {
    display: flex;
    justify-content: center;
    align-items: center;
}

.first-column-desktop {
    margin-right: 100px;
  }

.second-column-desktop {
    margin-left: 100px;
  }

.title-text {
    font-family: "CaviarBold";
    width: 544px;
    height: 52px;
    background-size: cover;
    text-align: center;
    font-size: 36px;
    white-space: nowrap;
    padding-top: 12px;
}

.lightening-title-text {
    background: url(https://d1nqkkjom3zmdm.cloudfront.net/services/lightening_applications.png) no-repeat center center;
}

.canvas-title-text {
    background: url(https://d1nqkkjom3zmdm.cloudfront.net/services/copper_honey_canvas.png) no-repeat center center;
}

.custom-coloring-title-text {
    background: url(https://d1nqkkjom3zmdm.cloudfront.net/services/custom_coloring.png) no-repeat center center;
}

.haircut-title-text {
    background: url(https://d1nqkkjom3zmdm.cloudfront.net/services/signature_haircut.png) no-repeat center center;
}

.blowout-title-text {
    background: url(https://d1nqkkjom3zmdm.cloudfront.net/services/signature_blowout.png) no-repeat center center;
}

.body-text {
    font-family: "Caviar";
    font-size: 24px;
    width: 544px;
    text-align: left;
}

.disclaimer-text {
    font-family: 'CaviarItalic';
    font-size: 24px;
    width: 544px;
    text-align: left;
}

.description-body-text {
    font-family: "Caviar";
    font-size: 24px;
    width: 544px;
    text-align: left;
}

.canvas-service-title {
    font-family: "CaviarBold";
    font-size: 24px;
    text-align: left;
}

.canvas-service-time {
    font-family: "CaviarItalic";
    font-size: 24px;
    text-align: left;
    display: inline;
}

.service-title {
    font-family: "CaviarBold";
    font-size: 24px;
    text-align: left;
}

.service-time {
    font-family: "CaviarItalic";
    font-size: 24px;
    text-align: left;
}

.lightening-single-service-container {
    padding-bottom: 5%;
}

.title-one {
    font-family: "Caroline";
    font-size: 48px;
    padding-top: 65px;
}

.title-two {
    font-family: "Caviar";
    font-size: 20px;
    padding-top: 32px;
    padding-bottom: 32px;
}

.click-here-link {
    color: black;
    border-bottom: 1px solid black;
    text-decoration: none;

}

.click-here-brands-link {
    text-decoration: none;
}

.read-more-link {
    color: black;
    border-bottom: 1.5px solid black;
    text-decoration: none;
    font-family: "Caviar";
    font-size: 20px;
}

.add-padding-bottom {
    padding-bottom: 5%;
}

@media (max-width: 1315px) {
    .lightening-title-text {
        width: 358px;
        height: 64px;
        padding-top: 32px;
    }

    .canvas-title-text {
        width: 358px;
        height: 64px;
        padding-top: 32px;
    }

    .custom-coloring-title-text {
        width: 358px;
        height: 64px;
        padding-top: 32px;
    }

    .haircut-title-text {
        width: 358px;
        height: 64px;
        padding-top: 32px;
    }

    .blowout-title-text {
        width: 358px;
        height: 64px;
        padding-top: 32px;
    }

    .description-body-text {
        width: 358px;
    }

    .title-one {
        font-size: 48px;
    }

    .title-two {
        font-size: 16px;
        padding-bottom: 16px;
    }

    .title-text {
        width: 358px;
        height: 64px;
        font-size: 24px;
        margin-bottom: 30px;
    }

    .body-text {
        width: 358px;
        padding-bottom: 20px;
        font-size: 16px;
    }

    .disclaimer-text {
        width: 358px;
        font-size: 16px;
    }

    .services-title-padding {
        display: none;
    }

    .lightening-single-service-container {
        padding-bottom: 5%;
    }

    .canvas-service-title {
        width: 358px;
    }
     
    .description-body-text {
        font-size: 16px;
    }
    
    .canvas-service-title {
        font-size: 16px;
    }
    
    .canvas-service-time {
        font-size: 16px;
    }
    
    .service-title {
        font-size: 16px;
    }
    
    .service-time {
        font-size: 16px;
    }

    .first-column-desktop {
        margin-right: 50px;
      }
    
    .second-column-desktop {
        margin-left: 50px;
      }
}

@media (max-width: 1000px) {
    .desktop-view-services {
        display: none;
    }
}

@media (min-width: 1001px) {
    .mobile-view-services {
        display: none;
    }
}