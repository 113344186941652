@font-face {
  font-family: 'Hanging Regular';
  src: url('/src/fonts/hanging/HangingRegular.ttf');
}

  .header-container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-right: 53px;
  }
  
  .header {
    height: 60px;
    background-color: #EFE6DD;
    display: flex;
    padding: 0.5rem 0rem;
    position:sticky;
    top:0;
    width: 100%;
    padding-top: 15px;
    z-index: 99;
  }

  .long-menu-header-links {
    display: flex;
  }

  .highlight-tab {
    background-color: #606453;
    font-size: 24px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom:23px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    color: #FFFFFF;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
  }

  .non-highlight-tab {
    color: #000000;
    background-color: #EFE6DD;
    font-size: 24px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom:23px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
  }

  .long-menu-header-links ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    list-style-type: none;
    align-items: center;
    font-family: 'Hanging Regular';
    font-size: 24px;
  }
  
  .long-menu-header-links ul li:not(:last-child) {
    margin-right: 150px;
  }


  .header-logo {
    margin-right: 200px;
  }

  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #ca8620;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    display: none;
  }

  .hamburger-drop-down {
    border: 0;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
  }

  .short-menu-item {
    display: flex;
    justify-content: space-between;
    list-style: none;
    list-style-type: none;
    align-items: center;
  }

  .short-menu-item a {
    font-weight: 400;
    color: #000000;
    text-decoration: none;
    font-family: 'Hanging Regular';
    font-size: 24px;
  }

  .short-menu-item a:hover {
    color: #70766A;
  }
  .short-menu-item a:active {
    color: #c97946;
  }

  @media (max-width: 1940px) {
    .long-menu-header-links ul li:not(:last-child) {
      margin-right: 100px;
    }
    .long-menu-header-links ul li:not(:last-child) {
      margin-right: 100px;
    }
    .header-logo {
      margin-right: 100px;
    }
  }

  @media (max-width: 1650px) {
    .long-menu-header-links ul li:not(:last-child) {
      margin-right: 65px;
    }
    .long-menu-header-links ul li:not(:last-child) {
      margin-right: 65px;
    }
    .header-logo {
      margin-right: 65px;
    }
  }

  @media (max-width: 1450px) {
    .long-menu-header-links ul li:not(:last-child) {
      margin-right: 35px;
    }
    .long-menu-header-links ul li:not(:last-child) {
      margin-right: 35px;
    }
    .header-logo {
      margin-right: 35px;
    }
  }

  @media (max-width: 1340px) {
    .long-menu-header-links ul li:not(:last-child) {
      margin-right: 20px;
    }
    .long-menu-header-links ul li:not(:last-child) {
      margin-right: 20px;
    }
    .header-logo {
      margin-right: 20px;
    }
  }

  @media (max-width: 1249px) {
    .long-menu-header-links ul li {
      display: none;
    }
  }

  @media (min-width: 1250px) {
    .hamburger-drop-down {
      display: none;
    }
  }
