@font-face {
  font-family: 'Cavier Dreams Bold';
  src: url('/src/fonts/caviar_dreams/CaviarDreams_Bold.ttf');
}

  .long-address {
    display: flex;
    align-items: center;
  }

  .short-address {
    display: none;
  }

  .large-footer-img {
    padding-left: 2%;
    padding-right: 3%;
  }

  .right-logo {
    margin-right: 50px;
    font-size: 12px;
  }

  .footer-container {
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .small-footer-img {
    margin-left: 20px;
  }
  
  .footer {
    height: 60px;
    background-color: #EDCAB7;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1.5rem 0rem;
    bottom: 0px;
    font-family: 'Cavier Dreams Bold';
    font-size: 14px;
    padding-bottom:22px; /*removes small gap underneath footer*/
  }

  .long-list {
    text-align: center;
    white-space: nowrap;
  }

  .long-list ul {
    display: flex;
    list-style-type: none;
    text-align: left;
    font-size: 14px;
  }
  
  .long-list ul li:not(:last-child) {
    margin-right: 60px;
  }
  .long-list ul li:last-child {
    margin-right: 40px;
  }
  
  .long-list ul a {
    font-weight: 400;
    color: #2f234f;
    text-decoration: none;
  }
  
  .long-list ul a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
  }
  
  .long-list ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
  }
  

  @media screen and (min-width: 1396px) {
    .short-list ul {
      display: none;
    }
    .small-footer-img {
      display: none;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 1395px) {

    .large-footer-img {
      display: none;
    }
  
    /* long list */
    .long-list ul {
      display: none;
    }

    /* short list */
    .short-list {
      width: 100%;
      margin-left: auto ;
      margin-right: auto ;
    }

    .short-list ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      list-style-type: none;
      overflow: hidden;
      gap: -10px;
    }
    
    .short-list ul li:not(:last-child) {
      margin-right: 32px;
    }

    .short-list ul li:first-child {
      margin-left: -28px;
    }

    .short-list ul li {
      font-size: 24px;
      font-weight: 400;
      color: #ca8620;
      line-height: 22px;
      padding: 0px;
    }

    .short-list ul a {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      text-decoration: none;
      display: flex;
    }
  }

  .short-list ul li {
    margin-right: 20px;
  }

@media (max-width:1475px) {
  .long-address {
    display: none;
  }
  .short-address {
    display: flex;
  }
}

@media (max-width:1735px) {
  .copyright-hide {
    display: none;
  }
}

@media (min-width:300px) {
  .short-list ul li {
    padding: 0%;
  }
  .short-list ul li:not(:last-child) {
    margin-right: 10px;
  }
  .short-list ul li {
    margin-right: 10px;
  }
  .right-logo {
    margin-left: 5px;
  }
}

@media (min-width:375px) {
  .short-list ul li:not(:last-child) {
    margin-right: 16px;
  }
}

@media (min-width:414px) {
  .short-list ul li:not(:last-child) {
    margin-right: 24px;
  }
}

/* Tablet */
@media (min-width:600px) {
  .short-list ul li {
      padding: 20px;
  }
  .short-list ul li {
    margin-right: 60px;
  }
}

/* Desktop */
@media (min-width:1200px) {
  .short-list ul li{
      padding: 30px;
  }
  .right-logo {
    margin-right: 50px;
  }
  .long-list ul li:not(:last-child) {
    margin-right: 80px;
  }
}

@media (min-width:2000px) {
  .long-list ul li:not(:last-child) {
    margin-right: 150px;
    margin-bottom: auto;
  }
}