@font-face {
    font-family: 'Caroline';
    src: url('/src/fonts/caroline/Caroline.ttf');
  }

  @font-face {
    font-family: 'Caviar Dreams Bold';
    src: url('/src/fonts/caviar_dreams/CaviarDreams_Bold.ttf');
  }

  .bold{
    font-family: "Caviar Dreams Bold";
    display: inline;
  }

.title {
    font-family: "Caroline";
    font-size: 64px;
    padding-bottom: 20px;
    padding-top: 50px;
}

.brands-container { 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }

.brand-header {
    font-family: "Caroline";
    height: 72px;
    background-size: cover;
    text-align: left;
    padding-left: 10px;
    margin-left: 25px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    font-size: 48px;
    white-space: nowrap;
    padding-top: 10px;
    color: #ffff;
}

.stack-row-container-productOne {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}

.stack-row-container-goldie-locks {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.productOne1-img {
    position: relative;
    width: 404px;
    height: 404px;
    margin-right: 150px;
    border-radius: 5px;
    bottom: 7px;
    filter: drop-shadow(20px 20px 25px #89674b);
  }

.productOne2-img {
    position: relative;
    width: 404px;
    height: 404px;
    margin-left: 150px;
    border-radius: 5px;
    bottom: 7px;
    filter: drop-shadow(20px 20px 25px #c7e3e1);
  }

.goldie-locks-large1-img {
  position: relative;
  width: 824px;
  height: 458px;
  border-radius: 5px;
  bottom: 7px;
  filter: drop-shadow(20px 20px 25px #E5D4C5);
}

.goldie-locks-large2-img {
  width: 824px;
  height: 824px;
  position: relative;
  border-radius: 5px;
  filter: drop-shadow(20px 20px 25px #EBE4DA);
}

.goldie-locks1-img {
  position: relative;
  width: 404px;
  height: 404px;
  margin-right: 150px;
  margin-top: 10px;
  border-radius: 5px;
  filter: drop-shadow(20px 20px 25px #F3F0EC);
}

.goldie-locks2-img {
  position: relative;
  width: 404px;
  height: 404px;
  margin-left: 150px;
  margin-top: 10px;
  border-radius: 5px;
  filter: drop-shadow(20px 20px 25px #F3F0EC);
}

.productOne-text-box {
    position: relative;
    text-align: left;
    font-family: "Caviar Dreams";
    font-size: 24px;
    margin-left: 7%;
    margin-right: 7%;
    border-radius: 5px;
    background-color: #E1F0EF;
    margin-top: 50px;
    display: inline-block;
    bottom: 100px;
    padding-top: 2%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 2%;
    filter: drop-shadow(5px 5px 10px #E1F0EF);
}

.shop-now-brands {
  font-size: 24px;
  color: black;
  border-bottom: 1.5px solid black;
  text-decoration: none;
  font-family: "Caviar";
}

.goldie-locks-text-box {
  position: relative;
  text-align: left;
  font-family: "Caviar Dreams";
  font-size: 24px;
  margin-left: 7%;
  margin-right: 7%;
  border-radius: 5px;
  background-color: #E2DAD4;
  margin-top: 50px;
  padding-top: 2%;
  padding-bottom: 1%;
  padding-left: 2%;
  filter: drop-shadow(5px 5px 10px #E2DAD4);
}

.product-header-text {
    font-family: "Caviar Dreams Bold";
    text-align: left;
    font-size: 36px;
  }

.read-more-brands {
  margin-top: 15px;
  margin-bottom: 35px;
}
.read-more-link-brands {
  font-size: 20px;
  color: black;
  border-bottom: 1.5px solid black;
  text-decoration: none;
  font-family: "Caviar";
}

@media (max-width: 1280px) {
  .productOne1-img {
    width: 303px;
    height: 303px;
    margin-right: 50px;
  }
  .productOne2-img {
    width: 303px;
    height: 303px;
    margin-left: 50px;
  }
  .goldie-locks1-img {
    width: 303px;
    height: 303px;
    margin-right: 50px;
  }
  .goldie-locks2-img {
    width: 303px;
    height: 303px;
    margin-left: 50px;
  }

  .goldie-locks-large1-img {
    width: 618px;
    height: 344px;
  }

  .goldie-locks-large2-img {
    width: 618px;
    height: 618px;
  }
}


@media (max-width: 1000px) {
    .desktop-view-brands {
      display: none;
    }
    .title {
      font-size: 48px;
    }
    .shop-now-brands {
      font-size: 20px;
    }
    .productOne1-img {
      width: 175px;
      height: 175px;
      margin-top: 20px;
      margin-right: 6px;
      bottom: 0px;
      filter: drop-shadow(10px 10px 25px #89674b);
    }
    .productOne2-img {
      width: 175px;
      height: 175px;
      margin-top: 20px;
      margin-left: 6px;
      bottom: 0px;
      filter: drop-shadow(10px 10px 25px #c7e3e1);
    }

    .goldie-locks-large1-img {
      width: 358px;
      height: 199px;
      margin-top: 20px;
      margin-left: 0px;
      bottom: 0px;
      filter: drop-shadow(10px 10px 25px #E5D4C5);
    }
    
    .goldie-locks-large2-img {
      width: 358px;
      height: 358px;
      margin-left: 25px;
      margin-top: 25px;
      display: flex;
    }

    .goldie-locks1-img {
      width: 175px;
      height: 175px;
      margin-top: 20px;
      margin-right: 6px;
      bottom: 7px;
    }
  
    .goldie-locks2-img {
      width: 175px;
      height: 175px;
      margin-top: 20px;
      margin-left: 6px;
      bottom: 7px;
    }

    .productOne-text-box-mobile {
      font-family: "Caroline";
      width: 358px;
      height: 130px;     
      padding-top: 10px;
      text-align: center;
      padding-left: 10px;
      margin-left: 25px;
      border-radius: 5px;
      font-size: 36px;
      white-space: nowrap;
      background-color: #E1F0EF;
      filter: drop-shadow(10px 10px 50px #808080);
  }

  .productOne-text-box {
    position: relative;
    text-align: left;
    font-family: "Caviar Dreams";
    font-size: 16px;
    margin-left: 25px;
    border-radius: 5px;
    background-color: #E1F0EF;
    margin-top: 50px;
    display: block;
    width: 313px;
    bottom: 25px;
    padding: 25px;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
}

  .goldie-locks-text-box-mobile {
    font-family: "Caroline";
    width: 358px;
    height: 130px;
    padding-top: 10px;
    text-align: center;
    padding-left: 10px;
    margin-left: 25px;
    border-radius: 5px;
    font-size: 36px;
    white-space: nowrap;
    background-color: #E2DAD4;
    filter: drop-shadow(10px 10px 50px #808080);
  }

  .goldie-locks-text-box {
    font-size: 14px;
    margin-left: 25px;
    margin-top: 20px;
    width: 310px;
    padding: 25px;
  }

    .brand-header {
      font-family: "Caroline";
      height: 55px;
      background-size: cover;
      text-align: left;
      padding-left: 10px;
      margin-left: 25px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      font-size: 36px;
      white-space: nowrap;
      padding-top: 10px;
      color: #ffff;
  }

  .product-header-text{
    font-size: 24px;
  }

  .stack-row-container-productOne {
      padding-left: 10px;
      margin-left: 15px;
      display: flex;
      justify-content: left;
      align-items: left;
      padding-bottom: 0px;
  }

  .stack-row-container-goldie-locks {
    padding-left: 10px;
    margin-left: 15px;
    display: flex;
    justify-content: left;
    align-items: left;
    padding-bottom: 0px;
}

}

@media (min-width: 1001px) {
    .mobile-view-brands {
      display: none;
    }
}