@font-face {
    font-family: 'Caroline';
    src: url('/src/fonts/caroline/Caroline.ttf');
  }

@font-face {
    font-family: 'CaviarItalic';
    src: url('/src/fonts/caviar_dreams/CaviarDreams_Italic.ttf');
  }

@font-face {
    font-family: 'Caviar';
    src: url('/src/fonts/caviar_dreams/CaviarDreams.ttf');
  }

@font-face {
    font-family: 'CaviarBold';
    src: url('/src/fonts/caviar_dreams/CaviarDreams_Bold.ttf');
  }


.artists-container { 
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("https://d1nqkkjom3zmdm.cloudfront.net/artists/ArtistsPageBackgroundImage.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

 .artists-title {
    font-family: "Caroline";
    font-size: 64px;
    padding-top: 65px;
}

.artists-sub-title {
    font-family: "Caroline";
    font-size: 40px;
    text-align: left;
    margin-left: 25px;
    padding-top: 50px;
    margin-bottom: 50px;
}

.sub-header-text {
    font-family: 'CaviarBold';
    font-size: 24px;
}

.owner-image {
    position: relative;
    overflow: hidden;
    width: 404px;
    height: 404px;
    border-radius: 5px;
    filter: drop-shadow(20px 20px 25px #F3F0EC);
}

.staff-image {
    position: relative;
    overflow: hidden;
    width: 264px;
    height: 264px;
    border-radius: 5px;
    filter: drop-shadow(20px 20px 25px #F3F0EC);
}

.artist-name {
    font-size: 24px;
    font-family: 'CaviarBold';
    padding-top: 15px;
}

.artist-contact-info {
    font-size: 20px;
    font-family: 'CaviarBold';
}

.artist-contact-info-read-more {
    font-size: 20px;
    font-family: 'CaviarBold';
    position: relative;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
}

.owner-staff-member-row-desktop{
    width: 1105px;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 50px; */
}

.artist-staff-member-row-desktop-3{
    width: 1384px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.artist-staff-member-row-desktop-less-than-3{
    margin-left: 300px;
    justify-content: center;
    margin-top: 50px;
}

.staff-member-container {
    margin-right: 297px;
}

.staff-member-profile-subtitle {
    font-family: "Caviar";
    font-size: 20px;
    margin-top: 10px;
}

/* Artist Read More Pages */
.read-more-artist-image {
    position: relative;
    overflow: hidden;
    width: 824px;
    height: 824px;
    border-radius: 5px;
    filter: drop-shadow(20px 20px 25px #F3F0EC);
}

.artist-bio-text-box{
    text-align: left;
    border-radius: 5px;
    width: 1384px;
    bottom: 0%;
    margin: auto;
    padding-bottom: 65px;
}

.ch-text-box-paragraph{
    text-align: left;
    font-family: "Caviar Dreams";
    font-size: 24px;
    border-radius: 5px;
    background-color: #EFE6DD;
    width: 100%;
    height: 100%;
    top: 0%;
    bottom: 0%;
    margin: auto;
    display: inline-block;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
}

/* Mobile */
.owner-staff-member-row-mobile {
    margin-top: 10px;
}
.artist-staff-member-row-mobile {
    margin-top: 10px;
}

@media (max-width: 1500px) {
    .owner-image {
        position: relative;
        overflow: hidden;
        width: 303px;
        height: 303px;
        border-radius: 5px;
        filter: drop-shadow(20px 20px 25px #F3F0EC);
    }
    .staff-image {
        position: relative;
        overflow: hidden;
        width: 198px;
        height: 198px;
        border-radius: 5px;
        filter: drop-shadow(20px 20px 25px #F3F0EC);
    }
    .artist-staff-member-row-desktop-3{
        width: 1000px;
    }
    .artist-staff-member-row-desktop-less-than-3{
        margin-left: 270px;
    }
    .owner-staff-member-row-desktop{
        width: auto;
        margin-left: 20%;
        margin-right: 20%;
        margin-top: 50px;
    }
    .artist-staff-member-row-desktop{
        width: auto;
        margin-left: 18%;
        margin-right: auto;
        margin-top: 50px;
        justify-content: center;
        align-items: center;
    }
    .artist-bio-text-box{
        width: 1038px;
    }
    .staff-member-container {
        margin-right: 223px;
    }
    .artist-name {
        font-size: 20px;
        padding-top: 15px;
    }
    .artist-contact-info {
        font-size: 16px;
    }
    .read-more-link {
        font-size: 16px;
    }
}

@media (max-width: 1300px) {
    .owner-image {
        position: relative;
        overflow: hidden;
        width: 225px;
        height: 225px;
        border-radius: 5px;
        filter: drop-shadow(20px 20px 25px #F3F0EC);
    }
    .staff-image {
        position: relative;
        overflow: hidden;
        width: 149px;
        height: 149px;
        border-radius: 5px;
        filter: drop-shadow(20px 20px 25px #F3F0EC);
    }
    .artist-staff-member-row-desktop-3{
        width: 800px;
    }
    .artist-staff-member-row-desktop-less-than-3{
        margin-left: 150px;
    }
    .owner-staff-member-row-desktop{
        width: auto;
        margin-left: 20%;
        margin-right: auto;
        margin-top: 50px;
    }
    .artist-staff-member-row-desktop{
        width: auto;
        margin-left: 165px;
        margin-right: auto;
        margin-top: 50px;
        justify-content: center;
        align-items: center;
    }
    .staff-member-container {
        margin-right: 167px;
    }
    .artist-bio-text-box{
        width: 900px;
    }
    .artist-name {
        font-size: 20px;
        padding-top: 15px;
    }
    .artist-contact-info {
        font-size: 16px;
    }
    .read-more-link {
        font-size: 12px;
    }
}

@media (max-width: 1000px) {
    .desktop-view-artists {
        display: none;
    }
    .artists-title {
        font-size: 48px;
        padding-top: 65px;
        padding-bottom: 50px;
    }
    .artists-sub-title {
        font-size: 36px;
        margin-left: 0px;
    }
    .staff-member-profile-subtitle {
        font-size: 16px;
        margin-top: 10px;
    }
    .staff-member-container {
        margin-right: 0px;
    }
    .owner-image {
        position: relative;
        overflow: hidden;
        width: 358px;
        height: 358px;
        border-radius: 5px;
        filter: drop-shadow(20px 20px 25px #F3F0EC);
    }
    .staff-image {
        position: relative;
        overflow: hidden;
        width: 358px;
        height: 358px;
        border-radius: 5px;
        filter: drop-shadow(20px 20px 25px #F3F0EC);
    }
    .artist-name {
        font-size: 20px;
        padding-top: 15px;
    }
    .artist-contact-info {
        font-size: 16px;
    }

    .artist-staff-member-row-desktop-less-than-3{
        margin-left: 0px;
    }

    /* Artist Read More Pages */
    .read-more-artist-image {
        position: relative;
        overflow: hidden;
        width: 358px;
        height: 358px;
        border-radius: 5px;
        filter: drop-shadow(20px 20px 25px #F3F0EC);
    }
    .ch-text-box-paragraph{
        text-align: left;
        font-family: "Caviar Dreams";
        font-size: 24px;
        border-radius: 5px;
        background-color: #EFE6DD;
        width: 308px;
        height: 100%;
        top: 0%;
        bottom: 0%;
        margin: auto;
        display: inline-block;
        padding: 25px;
        box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
    }
    .artist-bio-text-box{
        text-align: left;
        border-radius: 5px;
        width: 358px;
        bottom: 0%;
        margin: auto;
        padding-bottom: 65px;
    }
}

@media (min-width: 1001px) {
    .mobile-view-artists {
        display: none;
    }
}