@font-face {
    font-family: 'Caroline';
    src: url('/src/fonts/caroline/Caroline.ttf');
  }

@font-face {
    font-family: 'CaviarItalic';
    src: url('/src/fonts/caviar_dreams/CaviarDreams_Italic.ttf');
  }

@font-face {
    font-family: 'Caviar';
    src: url('/src/fonts/caviar_dreams/CaviarDreams.ttf');
  }

@font-face {
    font-family: 'CaviarBold';
    src: url('/src/fonts/caviar_dreams/CaviarDreams_Bold.ttf');
  }

.hair-extensions-container { 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }

  .desktop-view-hair-extensions {
    width: 1384px;
    margin: auto;
  }

  .extension-consultation-title-banner {
    background: url(https://d1nqkkjom3zmdm.cloudfront.net/hairExtensions/Extension_Consultation.png) no-repeat center center;
  }

  .maintenance-appointments-title-banner {
    background: url(https://d1nqkkjom3zmdm.cloudfront.net/hairExtensions/Maintenance_Appointments.png) no-repeat center center;
  }

  .new-guests-title-banner {
    background: url(https://d1nqkkjom3zmdm.cloudfront.net/hairExtensions/New_Guests.png) no-repeat center center;
  }

  .title-banner {
    font-family: "CaviarBold";
    height: 54px;
    background-size: cover;
    text-align: center;
    font-size: 36px;
    white-space: nowrap;
    padding-top: 10px;
    margin-top: 30px;
}

.he-service-title {
    font-family: "CaviarBold";
    font-size: 24px;
    text-align: left;
    display:inline;
    float:left;
}

.he-service-time {
    font-family: "CaviarItalic";
    font-size: 24px;
    text-align: left;
    padding-top: 3px;
    padding-left: 10px;
}

.service-description {
    font-family: "Caviar";
    font-size: 24px;
    text-align: left;
}

.note-container {
    font-family: "CaviarItalic";
    font-size: 24px;
    text-align: left;
}

.intro-text-body {
    font-family: "Caviar";
    font-size: 24px;
    text-align: left;
    padding-top: 30px;
}

.hair-extensions-title {
    font-family: "Caroline";
    font-size: 64px;
    padding-top: 65px;
}

.hand-tied-extension-img {
    width: 824px;
    height: 683px;
    margin-top: 40px;
    margin-bottom: 50px;
    filter: drop-shadow(20px 20px 25px #938777);
}

@media (max-width: 1400px) {
    .desktop-view-hair-extensions {
        width: auto;
        margin: auto;
        margin-right: 100px;
        margin-left: 100px;
      }

      .hand-tied-extension-img { 
        max-width: 100%;
        height: 456px;
        width: 550px;
      }
}

@media (max-width: 700px) {
    .desktop-view-hair-extensions {
        display: none;
    }
    .mobile-view-hair-extensions {
      width: 358px;
      margin: auto;
    }
    .title-banner {
      font-family: "CaviarBold";
      height: 46px;
      background-size: cover;
      text-align: center;
      font-size: 24px;
      white-space: nowrap;
      padding-top: 18px;
      margin-top: 30px;
  }
  .he-service-title {
    font-size: 16px;
}

.he-service-time {
    font-size: 16px;
}

.service-description {
    font-size: 16px;
}
.note-container {
  font-size: 16px;
}
.intro-text-body {
  font-size: 16px;
}
.hand-tied-extension-img { 
  height: auto;
  width: auto;
}

}

@media (min-width: 701px) {
    .mobile-view-hair-extensions {
        display: none;
    }
}