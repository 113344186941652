@import url('https://fonts.googleapis.com/css?family=Niramit%7CPoly');
@import url('https://book.salonbiz.com/css/theme/c6468t202209141420p33783rM5dyG4fu7zH72V/style.css');

.sbiz-olb-app-container {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;

  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  inset: 0px;
  z-index: 9999999;
  text-align: left;
}

.sbiz-olb-app-container--visible {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  opacity: 1;
}

.sbiz-olb-app-iframe {
  border: none;
  background-color: rgb(255, 255, 255);
  width: 100%;
  max-width: 648px !important;
  height: 100%;
}

.sbiz-olb-app-iframe--right {
  margin-left: calc(100% - 648px) !important;
}

.sbiz-olb-buttons {
  padding: 1em;
  display: inline-flex;
}

.sbiz-olb-buttons--stacked {
  flex-direction: column;
}

.sbiz-olb-buttons--stacked .sbiz-olb-button:first-child {
  margin-bottom: 1em;
}

.sbiz-olb-buttons--side-by-side {
  align-items: center;
}

.sbiz-olb-buttons--side-by-side .sbiz-olb-button:first-child {
  margin-right: 1em;
}

.sbiz-olb-button {
  display: block;
  font-family: 'Caviar';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0px 24px 48px rgba(2, 51, 40, 0.2);
  padding: 16px 32px;
  color: #525252;
  background-color: rgba(235, 203, 154, 255);
  width: 100%;
  text-decoration: none;
}

.sbiz-button-top {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  margin: 0 0 130px;
  padding: 10px 69.5px;
  font-family: "Caviar";
  height: 32px;
  text-align: center;
  white-space: nowrap;
  bottom: 300px;
  margin-bottom: -60px;
}

.sbiz-button-bottom {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  margin: 0 0 130px;
  padding: 10px 69.5px;
  font-family: "Caviar";
  height: 32px;
  text-align: center;
  white-space: nowrap;
  bottom: 110px;
  margin-bottom: -60px;
}

/* Sub Images Desktop / Mobile logic */
@media (max-width: 1750px) {
  .sbiz-button-top {
    margin: 0 0 0px;
    padding: 10px 24.5px;
    bottom: 245px;
  }
  
  .sbiz-button-bottom {
    margin: 0 0 0px;
    padding: 10px 24.5px;
    bottom: 160px;
  }
}

/* Mobile */

@media (max-width: 1500px) {
  .sbiz-button-top {
    margin: 0 0 0px;
    padding: 10px 24.5px;
    bottom: 205px;
  }
  
  .sbiz-button-bottom {
    margin: 0 0 0px;
    padding: 10px 24.5px;
    bottom: 150px;
  }
}